import GATSBY_COMPILED_MDX from "/github/workspace/content/pages/datenschutz.mdx";
import React from 'react';
import {Link} from 'gatsby';
import {MDXProvider} from '@mdx-js/react';
import Layout from '../components/layout';
import {Container, Grid, Typography} from '@mui/material';
import {StyledTypography} from '../styles/StyledTypography';
import {SEO} from '../components/seo';
var style = {
  ContainerMain: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: '120px 0px 40px 0px',
    maxWidth: '1280px'
  }
};
var shortcodes = {
  Link: Link
};
function PageTemplate(_ref) {
  var data = _ref.data, children = _ref.children;
  return React.createElement(React.Fragment, null, React.createElement(SEO, {
    title: data.mdx.frontmatter.title,
    description: data.mdx.frontmatter.title + " Digital Native Solutions GmbH"
  }), React.createElement(Layout, {
    pageTitle: data.mdx.frontmatter.title
  }, React.createElement(Container, null, React.createElement(Grid, {
    container: true,
    direction: "row",
    columnSpacing: 1,
    sx: style.ContainerMain
  }, React.createElement(Grid, {
    item: true,
    xs: 12
  }, React.createElement(StyledTypography, {
    sx: {
      typography: {
        xs: 'h5',
        sm: 'h4'
      }
    }
  }, data.mdx.frontmatter.title), React.createElement(Typography, {
    color: 'text.secondary',
    variant: "caption",
    fontSize: 16
  }, React.createElement(MDXProvider, {
    components: shortcodes
  }, children)))))));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(PageTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
var query = "2499994864";
